export const headerItems = [
    {
        sort_by: 'checkbox',
        styles: 'width:40px'
    },
    {
        sort_by: 'index',
        styles: 'width: 60px; display: flex; justify-content: center'
    },
    {
        classes: 'custom-table__row-name',
        styles: 'display: flex; justify-content: flex-start',
        sort_by: 'name',
        title: 'Name'
    },
    {
        classes: 'custom-table__row-updated-at',
        styles: 'justify-content: flex-start',
        sort_by: 'updated_at',
        title: 'Updated'
    },
    {
        classes: 'custom-table__row-created-at',
        styles: 'justify-content: flex-start',
        sort_by: 'created_at',
        title: 'Created'
    }
]
